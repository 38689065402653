import { useState, useEffect } from "react";
import Arquivo from ".";
import moment from "moment";

const ArquivoListar = (props) => {
  const [arquivos, setarquivos] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");
  const [tipos_arquivos] = useState([
    "Portifolio",
    "ISO 9001:2015",
    "ISO 14001:2015",
    "ISO 45001:2018",
    "Código de ética",
    "Revista",
  ]);

  const desativar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/desativararquivos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getarquivos();
      });
  };

  const getarquivos = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getarquivos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setarquivos(result);
      });
  };

  useEffect(() => {
    getarquivos();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desativar </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                Deseja realmente desativar o registro?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    desativar();
                  }}
                >
                  Desativar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Arquivo</th>
            <th scope="col">Tipo</th>
            <th scope="col">Data de cadastro</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">
              Editar
            </th>
            <th scope="col" className="text-center">
              Desativar/Ativar
            </th>
          </tr>
        </thead>
        <tbody>
          {arquivos.length
            ? arquivos.map((t, index) => {
                return (
                  <tr
                    key={index + "_arquivos"}
                    className={!t.status ? "" : "desativado"}
                  >
                    <th scope="row">{index}</th>
                    <td>
                      <a href={"/uploads/" + t.file}>{t.file}</a>
                    </td>
                    <td>{tipos_arquivos[t.tipo - 1]}</td>
                    <td>{moment(t.created_at).format("DD/MM/YYYY")}</td>
                    <td>{!t.status ? "Desativado" : "Ativo"}</td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          props.setconteudo(
                            <Arquivo inputs={t} editar="true" />
                          );
                          props.setTituloConteudo("Editar arquivo");
                          props.setbread("Arquivo / Listar / Editar");
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o blue"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                    <td align="middle">
                      <span
                        className={t.status ? "icons green" : "icons red"}
                        onClick={() => {
                          setmodal(true);
                          setId(t.id);
                        }}
                      >
                        <i
                          className={t.status ? "fa fa-check" : "fa fa-times"}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </>
  );
};

export default ArquivoListar;
