import { Navigate } from "react-router-dom";
import { Admin } from "./admin";

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem("usuario"));
  return user === null ? false : true;
};

export const ProtectedRoute = () => {
  const isAuth = useAuth();
  return isAuth ? <Admin /> : <Navigate to="/login" replace />;
};
