import { useState } from "react";

const Modal = (props) => {
  const [email, setemail] = useState("");
  const [alert, setalert] = useState(false);
  const [message, setmessage] = useState("");
  const [style, setstyle] = useState("");
  const [params] = useState(props.params);
  return (
    <div className="modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Esqueci minha senha</h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                props.action(false);
              }}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={params.class ? params.class : "modal-body"}>
            {alert && (
              <div className={"alert alert-" + style} role="alert">
                {message}
              </div>
            )}

            <p>
              Para recuperar a sua senha digite abaixo o e-mail cadastrado no
              sistema.
            </p>
            <input
              type="text"
              value={email}
              onChange={(e) => {
                props.setmail(e.target.value);
                setemail(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
                  setstyle("danger");
                  setmessage("Formato de email inválido");
                  setalert(true);
                  return;
                }
                setemail("");
                setalert(true);
                setstyle("success");
                setmessage(
                  "Link de recuperação enviado para o email cadastrado no sistema"
                );
                props.submit();
              }}
            >
              Recuperar Senha
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                props.action(false);
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
