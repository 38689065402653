import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Recuperar = (props) => {
  const [, setpassword2] = useState("");
  const [, setpassword] = useState("");
  const [message] = useState("");
  const [alert] = useState(false);
  const [email] = useState("");
  const [style] = useState("");
  const navigate = useNavigate();

  const handle = () => {
    navigate("/admin");
  };

  const enviar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/recuperarsenha", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ mail: email }),
    })
      .then((response) => response.json())
      .then((result) => {});
  };

  return (
    <div className="container">
      <div className="card card-container" style={{ marginTop: "200px" }}>
        <img
          src="img/hebert_home.png"
          width="150px"
          alt="logo"
          style={{ marginLeft: "50px", marginTop: "15px" }}
        ></img>

        <p id="profile-name" className="profile-name-card"></p>

        {alert && (
          <div className={"alert alert-" + style} role="alert">
            {message}
          </div>
        )}

        <form className="form-signin">
          <span id="reauth-email" className="reauth-email"></span>
          <input
            type="password"
            id="password2"
            className="form-control"
            placeholder="Senha"
            required
            autoFocus
            onChange={(e) => {
              setpassword2(e.target.value);
            }}
          />
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            required
            onChange={(e) => {
              setpassword(e.target.value);
            }}
          />
          <button
            className="btnl btn-lg btn-primary btn-block btn-signin"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={enviar}
          >
            Alterar senha
          </button>
        </form>
      </div>
    </div>
  );
};
