import { useState, useEffect } from "react";
import Cliente from ".";

const ClienteLista = (props) => {
  const [clientes, setClientes] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");

  const desativar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/desativarclientes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getClientes();
      });
  };

  const getClientes = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getclientes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setClientes(result);
      });
  };

  useEffect(() => {
    getClientes();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desativar </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                Deseja realmente desativar o registro?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    desativar();
                  }}
                >
                  Desativar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Imagem</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">
              Editar
            </th>
            <th scope="col" className="text-center">
              Desativar
            </th>
          </tr>
        </thead>
        <tbody>
          {clientes.length
            ? clientes.map((t, index) => {
                return (
                  <tr key={index + "_clientes"}>
                    <th scope="row">{index}</th>
                    <td>
                      <a href={"/uploads/" + t.file}>{t.file}</a>
                    </td>
                    <td>{!t.status ? "Desativado" : "Ativo"}</td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          props.setconteudo(
                            <Cliente inputs={t} editar="true" />
                          );
                          props.setTituloConteudo("Editar cliente");
                          props.setbread("Clientes / Listar / Editar");
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o blue"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                    <td align="middle">
                      <span
                        className="icons red"
                        onClick={() => {
                          setmodal(true);
                          setId(t.id);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </>
  );
};

export default ClienteLista;
