import "./login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../modal";

export const Login = (props) => {
  const [login, setlogin] = useState("");
  const [password, setpassword] = useState("");
  const [message, setmessage] = useState("");
  const [alert, setalert] = useState(false);
  const [style, setstyle] = useState("");
  const [modal, setmodal] = useState(false);
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  const handle = () => {
    navigate("/admin");
  };

  const enviar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/recuperarsenha", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ mail: email }),
    })
      .then((response) => response.json())
      .then((result) => {});
  };

  const logar = async () => {
    try {
      if (login === "" || password === "") {
        setalert(true);
        setstyle("danger");
        setmessage("Todos os campos são obrigatórios");
        return;
      }

      await fetch("https://www.hebertengenharia.com.br/api/logar", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ login: login, senha: password }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            setstyle("danger");
            setmessage("Senha ou Login de acesso inválidos");
            setalert(true);
          } else {
            localStorage.setItem("usuario", JSON.stringify(result));

            handle();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      {modal && (
        <Modal
          action={setmodal}
          submit={enviar}
          setmail={setemail}
          params={{ class: "modal-body, modal-altura" }}
        />
      )}
      <div className="card card-container" style={{ marginTop: "200px" }}>
        <img
          src="img/hebert_home.png"
          width="150px"
          alt="logo"
          style={{ marginLeft: "50px", marginTop: "15px" }}
        ></img>

        <p id="profile-name" className="profile-name-card"></p>

        {alert && (
          <div className={"alert alert-" + style} role="alert">
            {message}
          </div>
        )}

        <form className="form-signin">
          <span id="reauth-email" className="reauth-email"></span>
          <input
            type="text"
            id="login"
            className="form-control"
            placeholder="Login"
            required
            autoFocus
            onChange={(e) => {
              setlogin(e.target.value);
            }}
          />
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Password"
            required
            onChange={(e) => {
              setpassword(e.target.value);
            }}
          />
          <button
            className="btnl btn-lg btn-primary btn-block btn-signin"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={logar}
          >
            Logar
          </button>
        </form>
        <button
          className="forgot-password"
          style={{ marginLeft: "60px" }}
          onClick={() => {
            setmodal(true);
          }}
        >
          Esqueci minha senha?
        </button>
      </div>
    </div>
  );
};
