import { useState } from "react";

export const Features = (props) => {
  const [pop, setPop] = useState(false);

  return (
    <div>
      {pop && (
        <div className="pop">
          <div
            className="close"
            onClick={() => {
              setPop(false);
            }}
          >
            <i className="fa fa-times"></i>
          </div>

          <div className="tabela">
            <h4>Nosso serviços</h4>
            <div className="mt-3">
              Atuante em nível nacional e certificada pela{" "}
              <a href="/img/ISO-9001.pdf">ISO 9001:2015</a>,{" "}
              <a href="/img/ISO-14001.pdf">ISO 14001:2015</a>,{" "}
              <a href="/img/ISO-45001.pdf">ISO 45001:2018</a> a Hebert
              Engenharia presta serviços para as maiores empresas do país nos
              setores de óleo e gás, siderurgia, cimento, energia e mineração.{" "}
              <br />
              <br />A empresa está habilitada no CRC Petrobras / Transpetro para
              execução de serviços como:
            </div>
            <br />
            <table className="table table-bordered table-hover">
              <thead className="cabecalho">
                <tr>
                  <th scope="col">Família de Serviços </th>
                  <th scope="col">Prestador de Serviço</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>99006431</th>
                  <th>Conservação de jardins</th>
                </tr>

                <tr>
                  <th>99006430</th>
                  <th>Limpeza Predial</th>
                </tr>

                <tr>
                  <th>99006009</th>
                  <th>Serviço de gestão de facilities prediais</th>
                </tr>

                <tr>
                  <th>99005770</th>
                  <th>Transporte de pessoas em veículos de pequeno porte</th>
                </tr>

                <tr>
                  <th>99008020</th>
                  <th>Paradas de Manutenção de Unidades de Grande Porte</th>
                </tr>

                <tr>
                  <th>99007870</th>
                  <th>
                    Serviços de Manutenção de Sistemas Industriais: Elétrica,
                    Instrumentação e Automação
                  </th>
                </tr>

                <tr>
                  <th>99007182</th>
                  <th>Instalação e manutenção elétrica industrial</th>
                </tr>

                <tr>
                  <th>99006363</th>
                  <th>Manutenção em equipamentos estáticos - Caldeiraria.</th>
                </tr>

                <tr>
                  <th>99006353</th>
                  <th>Manutenção de estrutura metálica</th>
                </tr>

                <tr>
                  <th>99006210</th>
                  <th>Serviço de manutenção de instrumentos de processo</th>
                </tr>

                <tr>
                  <th>99006208</th>
                  <th>Manutenção de tubulação</th>
                </tr>

                <tr>
                  <th>99006138</th>
                  <th>Serviço de limpeza de área industrial</th>
                </tr>

                <tr>
                  <th>99007590</th>
                  <th>Paradas de Manutenção</th>
                </tr>

                <tr>
                  <th>99006354</th>
                  <th>Montagem de tubulação</th>
                </tr>

                <tr>
                  <th>99006265</th>
                  <th>
                    Captação, adução, distribuição de água e esgoto sanitário
                  </th>
                </tr>

                <tr>
                  <th>99006261</th>
                  <th>Construção de estação de tratamento</th>
                </tr>

                <tr>
                  <th>99006242</th>
                  <th>Obras de Fundação</th>
                </tr>

                <tr>
                  <th>99006240</th>
                  <th>Impermeabilização de superfícies em obras civis</th>
                </tr>

                <tr>
                  <th>99006231</th>
                  <th>Construção de estruturas de concreto</th>
                </tr>

                <tr>
                  <th>99006230</th>
                  <th>Reforço ou recuperação em estrutura de concreto</th>
                </tr>

                <tr>
                  <th>99006165</th>
                  <th>Construção e montagem de estruturas metálicas</th>
                </tr>

                <tr>
                  <th>99006164</th>
                  <th>Construção de edificações</th>
                </tr>

                <tr>
                  <th>99006049</th>
                  <th>Manutenção Predial</th>
                </tr>

                <tr>
                  <th>99006048</th>
                  <th>Serviços de instalações prediais</th>
                </tr>

                <tr>
                  <th>99006044</th>
                  <th>Manutenção e inspeção de pistas de dutos</th>
                </tr>

                <tr>
                  <th>99006043</th>
                  <th>Construção de pistas e pavimentação de vias</th>
                </tr>

                <tr>
                  <th>99006042</th>
                  <th>Terraplenagem</th>
                </tr>

                <tr>
                  <th>99006019</th>
                  <th>Projeto de fundações</th>
                </tr>

                <tr>
                  <th>99006017</th>
                  <th>Projeto de estruturas</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div id="features" className="text-center">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <br />
              <br />
              <br />
              <h2>Nosso negócio</h2>
              <div className="caixaAzul ">
                <div className="quadradoCol">
                  <div className="subtitulo">Nossos Serviços</div>
                  <p>
                    Conheça os serviços que a Hebert Engenharia está apta a
                    oferecer
                  </p>
                  <span
                    className="btn btn-custom2 btn-lg"
                    onClick={() => {
                      setPop(true);
                    }}
                  >
                    Conheça mais
                  </span>
                </div>
                <div className="quadradoRow">
                  <div className="quadradoRowIcon" style={{ paddingRight: 10 }}>
                    <img
                      src="img/clock.png"
                      width={70}
                      style={{ marginTop: 30 }}
                      alt="clock"
                    />
                  </div>
                  <div>
                    <div className="subtitulo"> Respeito aos prazos</div>A
                    empresa é conhecida por seus clientes pelo seu controle
                    rigoroso em sua rotina de trabalho respeitando prazos e
                    cronogramas.
                  </div>
                </div>

                <div className="quadradoRow">
                  <div className="quadradoRowIcon" style={{ paddingRight: 10 }}>
                    <img
                      src="img/handshake.png"
                      width={100}
                      style={{ marginTop: 20 }}
                      alt="handshake"
                    />
                  </div>
                  <div>
                    <div className="subtitulo">Confiabilidade</div>
                    As obras industriais mais elaboradas e complexas são
                    confiadas por nossos clientes e estas são acompanhadas de
                    forma detalhada e com a máxima atenção por nossa equipe
                    técnica
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
