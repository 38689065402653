import { useState, useEffect } from "react";
import "./style.css";
const Team = (props) => {
  const [message, setmessage] = useState("");
  const [alert, setalert] = useState(false);
  const [style, setstyle] = useState("");
  const [file, setFile] = useState("");

  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});

  useEffect(() => {
    var toggler = document.getElementsByClassName("seta");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("seta-down");
      });
    }
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    salvar();
  };

  const validarFormatoImg = (value) => {
    setalert(false);
    var formatos = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
    ];
    return formatos.includes(value);
  };

  const salvar = async () => {
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
      inputs["file"] = file.name;
    }

    formData.append("team", JSON.stringify(inputs));
    await fetch("https://www.hebertengenharia.com.br/api/salvartime", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setalert("true");
          setstyle("success");
          setmessage("Dados gravados na base com sucesso!");
          setInputs({});
          document.getElementById("file").value = "";
        } else {
          setalert("true");
          setstyle("danger");
          setmessage("Ocorreu um erro ao tentar gravar os dados");
        }
      });
  };

  return (
    <div className="row" style={{ padding: "20px" }}>
      <div className="col-6">
        {alert && (
          <div className={"alert alert-" + style} role="alert">
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              name="nome"
              className="form-control"
              value={inputs.nome || ""}
              onChange={handleChange}
              placeholder="nome"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo nome é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red ">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Cargo</label>
            <input
              type="text"
              name="cargo"
              className="form-control"
              value={inputs.cargo || ""}
              onChange={handleChange}
              placeholder="cargo"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo cargo é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red ">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Depoimento</label>
            <textarea
              type="text"
              name="depoimento"
              className="form-control"
              value={inputs.depoimento || ""}
              onChange={handleChange}
              placeholder="Depoimento"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo depoimento é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            ></textarea>
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Foto </label>
            <input
              type="file"
              name="file"
              id="file"
              className="form-control"
              accept=".jpg, .gif, .bmp, .png, .jpeg"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo foto é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              onChange={(e) => {
                if (validarFormatoImg(e.target.files[0].type)) {
                  setFile(e.target.files[0]);
                } else {
                  setalert(true);
                  setmessage("Formato da imagem inválida");
                  setstyle("danger");
                }
              }}
              placeholder="selecione um arquivo"
            />

            {props.editar && props.inputs["file"] && (
              <small id="emailHelp" className="form-text text-muted blue">
                Arquivo:{"  "}
                <a href={"uploads/" + props.inputs["file"]}>
                  <span style={{ color: "#323232" }}>
                    {props.inputs["file"]}
                  </span>
                </a>
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Status </label>
            <select
              className="form-control"
              name="status"
              value={inputs.status || ""}
              onChange={handleChange}
              aria-label="Default select example"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo status é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Ativo</option>
              <option value="0">Desativar</option>
            </select>
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Team;
