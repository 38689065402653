import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Obras recem executadas</h2>
          <p>Responsabilidade e Qualidade</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.obras
              ? props.obras.map((d, i) => (
                  <div
                    key={"galeria-" + i}
                    className="col-sm-5 col-md-3 col-lg-3"
                  >
                    <Image
                      title={d.titulo}
                      largeImage={
                        "http://www.hebertengenharia.com.br/uploads/" + d.file
                      }
                      smallImage={
                        "http://www.hebertengenharia.com.br/uploads/" + d.file
                      }
                    />
                    <div className="barraAzul">
                      <div className="titulo_">{d.titulo}</div>
                      <div className="subtitulo_">{d.subtitulo}</div>
                    </div>
                  </div>
                ))
              : "Carregando..."}
          </div>
        </div>
      </div>
    </div>
  );
};
