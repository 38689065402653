import SmoothScroll from "smooth-scroll";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./components/home";
import { Login } from "./components/login/index";
import { ProtectedRoute } from "./components/protectedRoute";
import { ListaVagasDisponiveis } from "./components/vagasDisponiveis/listaVagasDisponiveis";
import { ListaCampanhas } from "./components/campanha/listaCampanhas";
import { Recuperar } from "./components/recuperar/index";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/*" element={<Login />}></Route>
        <Route path="/" element={<Home />}></Route>
        <Route path="*" element={<Home />}></Route>
        <Route path="/vagas" element={<ListaVagasDisponiveis />}></Route>
        <Route path="/vagas/*" element={<ListaVagasDisponiveis />}></Route>
        <Route path="/admin" element={<ProtectedRoute />}></Route>
        <Route path="/recuperarSenha/*" element={<Recuperar />}></Route>
        <Route path="/admin/*" element={<ProtectedRoute />}></Route>
        <Route path="/sms" element={< ListaCampanhas />}></Route>
        <Route path="/sms/*" element={<ListaCampanhas />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
