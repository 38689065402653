export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Programa de Integridade</h2>
          <p>
            A Hebert Engenharia tem consciência dos seus deveres com a ética e o
            bem estar de seus clientes, colaboradores, fornecedores e da
            sociedade como um todo e, por isso, desenvolveu e implementou seu
            Programa de Integridade, à luz da Lei 12.846/2013 e do Decreto
            8.420/2015. Neste sentido é compromisso da Direção da Hebert
            Engenharia o provimento dos recursos necessários ao devido
            cumprimento de seu Programa de Integridade.
          </p>
          <br />
          <a
            href="documentos/codigo_etica_hebert.pdf"
            className="btn btn-custom2 btn-lg"
            style={{ background: "none" }}
            target="_blank"
          >
            Baixar o código de ética
          </a>
        </div>
        {/* <div className="row">
          <div className="col-md-4">
            <i className=""></i>
            <div className="service-desc">
              <h3>name</h3>
              <p>text</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
