import { useState, useEffect } from "react";
import Formulario from "./formulario";
import moment from "moment";
export const ListaVagasDisponiveis = (props) => {
  const [vagas, setvagas] = useState([]);
  const [modal, setmodal] = useState(false);
  const [vaga, setvaga] = useState();
  const [vaga_titulo, setvagaTitulo] = useState();
  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});

  function classToggle(el) {
    var element = el.children.length > 0 ? el.children[0] : el;

    if (element.classList[1] === "fa-arrow-circle-o-down") {
      element.classList.add("fa-arrow-circle-o-up");
    } else {
      element.classList.add("fa-arrow-circle-o-down");
    }
    element.classList.toggle(element.classList[1]);
  }

  const buscar = async () => {
    await fetch(
      "https://www.hebertengenharia.com.br/api/getVagasDisponiveisBusca",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ termo: inputs.busca }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setvagas(result);
      });
  };

  const getVagas = async () => {
    try {
      const response = await fetch(
        "https://www.hebertengenharia.com.br/api/getVagasDisponiveis",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setvagas(result); // Assuming setvagas is a function to update state or variable
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    getVagas();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      <div className="container">
        {modal && (
          <div className="modal" tabIndex="-1" role="dialog">
            <div
              className="modal-dialog modal-lg modal-dialog-scrollable"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Cadastrar Currículo </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setmodal(false);
                    }}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <Formulario vaga={vaga} vaga_titulo={vaga_titulo} />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setmodal(false);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="fill">
          <div className="menuVagas">
            <div className="logoVagas">
              <img src="img/hebert_home.png" width="100px" alt="logo"></img>
            </div>
            <ul className="menuVagasDisponiveis">
              <a href="#vagas">
                <li>
                  <i className="fa fa-briefcase" aria-hidden="true"></i> Vagas
                  Disponíveis
                </li>
              </a>
              <a href="#busca">
                <li>
                  <i className="fa fa-search" aria-hidden="true"></i> Buscar
                  vagas
                </li>
              </a>
            </ul>
          </div>
          <div className="introLargura">
            <div className="b01">
              <div className="intrasmall">Trabalhe conosco</div>
              <div className="introducao_sub">
                QUAL É A SUA PAIXÃO? AQUI NA Hebert Engenharia VOCÊ ENCONTRA A
                VAGA CERTA, DE ACORDO COM O SEU PERFIL PROFISSIONAL.
              </div>
            </div>
          </div>
          <div className="divVagas" id="busca">
            <div className="txtvagas">
              Encontre a melhor oportunidade <br /> na Hebert Engenharia
            </div>

            <div className="inputBusca">
              <div style={{ width: "95%" }}>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.busca || ""}
                  onChange={handleChange}
                  name="busca"
                  style={{ borderRadius: "10px", padding: "20px" }}
                  placeholder="Procure por palavra chave"
                />
              </div>
              <div style={{ width: "5%" }}>
                <button
                  className="btn btn-primary"
                  style={{
                    padding: "15px",
                    borderRadius: "12px",
                    marginLeft: "5px",
                    marginTop: "-2px",
                  }}
                  onClick={() => {
                    buscar();
                  }}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>

          {/* <div id="avisoManutencao">
            <h3>Estamos em manutenção,</h3>
            <h3>agradecemos pela sua paciência!</h3>
            <img src="https://cdn2.iconfinder.com/data/icons/marketing-and-seo-48/512/017_SEO_Maintenance-256.png"></img>
          </div>    */}
          <div className="list-group" id="vagas">
            {vagas.length ? (
              vagas.map((vaga, index) => {
                return (
                  <div
                    className="list-group-item list-group-item-action"
                    key={"vagas_" + index}
                  >
                    <div className="row">
                      <div className="col-10">
                        <div className="d-flex w-100 justify-content-between">
                          <h5
                            className="mb-1"
                            style={{ color: "#337ab7", fontSize: "18px" }}
                          >
                            <i
                              className="fa fa-briefcase"
                              aria-hidden="true"
                            ></i>{" "}
                            {vaga.titulo}
                          </h5>

                          <small>
                            Data de Cadastro{" "}
                            <i
                              className="fa fa-clock-o mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            {moment(vaga.data).format("DD - MMM - YYYY")}
                          </small>
                        </div>

                        <p className="mb-1">
                          {vaga.descricao.substr(0, 150) + "..."}
                        </p>

                        <small
                          style={{
                            color: "#337ab7",
                            cursor: "pointer",
                          }}
                        >
                          <a
                            data-toggle="collapse"
                            href={"#descricao_" + index}
                            role="button"
                            aria-expanded="false"
                            aria-controls="descricao"
                            onClick={(el) => {
                              classToggle(el.target);
                            }}
                          >
                            Clique aqui para ver a descrição completa{" "}
                            <i
                              className="fa fa-arrow-circle-o-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </small>

                        <div className="collapse" id={"descricao_" + index}>
                          <br />

                          <div className="card">
                            <div className="card-body" id="card-vaga">
                              <h5 className="card-title">
                                <i
                                  className="fa fa-briefcase"
                                  aria-hidden="true"
                                ></i>{" "}
                                {vaga.titulo}
                              </h5>
                              <h6 className="card-subtitle mb-2 text-muted">
                                Data de Cadastro{" "}
                                <i
                                  className="fa fa-clock-o mr-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                {moment(vaga.data).format("DD - MMM - YYYY")}
                              </h6>
                              <p className="card-text">{vaga.descricao}</p>
                              {vaga.file && (
                                <a
                                  href={
                                    "http://www.hebertengenharia.com.br/uploads/" +
                                    vaga.file
                                  }
                                  className="card-link"
                                >
                                  <i
                                    className="fa fa-paperclip mr-2"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Clique aqui para baixar o anexo
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-2">
                        <button
                          className="btn btn-primary botao"
                          onClick={() => {
                            setmodal(true);
                            setvaga(vaga.id);
                            setvagaTitulo(vaga.titulo);
                          }}
                        >
                          Quero me candidatar
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="list-group-item list-group-item-action text-center">
                    <div className="d-flex w-100 justify-content-between">
                      <i
                        className="fa fa-suitcase fa-5x"
                        aria-hidden="true"
                        style={{ color: "#f0f0f0" }}
                      ></i>

                      <h5 className="mb-1">
                        Nenhuma vaga disponível no momento{" "}
                      </h5>
                    </div>
                    <p className="mb-1  text-center">
                      Faça parte do nosso Banco de Talentos.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary btvaga"
                      onClick={() => {
                        setmodal(true);
                      }}
                    >
                      Faça aqui o cadastro do seu currículo
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
