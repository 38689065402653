import { useState } from "react";
import "./style.css";
const Vaga = (props) => {
  const [message, setmessage] = useState([]);
  const [alert, setalert] = useState(false);
  const [style, setstyle] = useState("");
  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});
  const [file, setFile] = useState("");

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    salvar();
  };

  const salvar = async () => {
    let formData = new FormData();

    if (file) {
      formData.append("file", file);
      inputs["file"] = file.name;
    }

    formData.append("vagas", JSON.stringify(inputs));

    await fetch("https://www.hebertengenharia.com.br/api/salvarvagas", {
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setalert(true);
          setstyle("success");
          setmessage("Dados inseridos com sucesso.");
          setInputs({});
          document.getElementById("file").value = "";
        } else {
          setalert(true);
          setstyle("danger");
          setmessage("Ocorreu um erro ao inserir os dados.");
        }
      });
  };

  return (
    <div className="row" style={{ padding: "20px" }}>
      <div className="col-6">
        {alert && (
          <div className={"alert alert-" + style} role="alert">
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Título</label>
            <input
              type="text"
              name="titulo"
              className="form-control"
              value={inputs.titulo || ""}
              onChange={handleChange}
              placeholder="Título"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo titulo é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red ">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Data disponibilidade</label>
            <input
              type="date"
              name="data"
              className="form-control"
              value={inputs.data || ""}
              onChange={handleChange}
              placeholder="Login"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo data é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Descrição </label>
            <textarea
              name="descricao"
              value={inputs.descricao || ""}
              onChange={handleChange}
              className="form-control"
              aria-label="With textarea"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo descricao é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            ></textarea>
            <small className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Anexo </label>
            <input
              type="file"
              name="file"
              id="file"
              className="form-control"
              accept=".pdf, .doc, .txt, .pps, .ppsx, .xlsx, .xls"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
              placeholder="selecione um arquivo"
            />

            {props.editar && props.inputs["file"] && (
              <small id="emailHelp" className="form-text text-muted blue">
                Arquivo:{"  "}
                <a href={"uploads/" + props.inputs["file"]}>
                  <span style={{ color: "#323232" }}>
                    {props.inputs["file"]}
                  </span>
                </a>
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control"
              name="status"
              value={inputs.status || ""}
              onChange={handleChange}
              aria-label="Default select example"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo status é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Ativo</option>
              <option value="0">Desativar</option>
            </select>
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Vaga;
