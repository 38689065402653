import { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import moment from "moment";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CandidatoVaga = (props) => {
  const [candidatos, setCandidatos] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");
  const [busca, setBusca] = useState("");
  const [pages, setPages] = useState([]);
  const [candidato, setCandidato] = useState({});
  const [dadosExportar, setDadosExportar] = useState([]);
  // const [message, setmessage] = useState([]);
  // const [file, setFile] = useState("");
  // const [files, setFiles] = useState([]);
  const [paginaAtual, setPaginaAtual] = useState(0);

  const selectCheck = (id_vaga, index) => {
    setDadosExportar([]);
    let elements = document.getElementsByClassName("checkbox");

    for (var i = 0; i < elements.length; i++) {
      if (elements[i].checked && i !== index) {
        elements[i].checked = false;
      }
    }
    setId(id_vaga === id ? undefined : id_vaga);

    let data = candidatos.filter((c) => c.id_vaga === id_vaga);
    setDadosExportar(data[0].candidatos);
  };

  const buscarVagasCandidato = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/buscarVagasCandidato", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        pagina: 0,
        paginaoffset: 8000,
        termo: busca,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        var agrupado = groupBy(result);
        let p = Math.ceil(agrupado.length / 15);
        let arr = [];
        for (let index = 0; index < p; index++) {
          arr.push(index);
        }
        setPages(arr);
        setCandidatos(agrupado);
      });
  };

  const getCandidatos = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getcandidatovaga", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        /* pagina: paginaAtual,
        paginaoffset: paginaAtual * 15 === 0 ? 15 : paginaAtual * 15 */
        pagina: 0,
        paginaoffset: 8000
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        result = result.map((r) => {
          var candidato = r;
          candidato.documentos = candidato.documentos
            ? "https://www.hebertengenharia.com.br/uploads/" +
              candidato.documentos
            : "Nenhum curriculo cadastrado";
          return candidato;
        });

        var agrupado = groupBy(result);
        let p = Math.ceil(agrupado.length / 15);
        let arr = [];
        for (let index = 0; index < p; index++) {
          arr.push(index);
        }
        setPages(arr);
        setCandidatos(agrupado);
      });
  };

  function groupBy(arr) {
    let memory = {
      temp: [],
      result: [],
    };

    arr.map((o) => {
      if (!memory.temp.includes(o.titulo)) {
        memory.temp.push(o.titulo);
        const candidatos = arr.filter((t) => t.titulo === o.titulo);
        memory.result.push({
          titulo: o.titulo,
          candidatos: candidatos,
          id_vaga: o.id_vaga,
        });
      }
    });
    return memory.result;
  }

  useEffect(() => {
    getCandidatos();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Dados do candidato </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-body2  text-center">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-nome"
                        className="col-form-label"
                      >
                        Nome completo:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.nome || ""}
                        readOnly={true}
                        name="nome"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-email"
                        className="col-form-label"
                      >
                        Email:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.email || ""}
                        readOnly={true}
                        name="email"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-telefone"
                        className="col-form-label"
                      >
                        Telefone:
                      </label>
                      <InputMask
                        mask="(99)99999-9999"
                        className="form-control"
                        value={candidato.telefone || ""}
                        readOnly={true}
                        name="telefone"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-datanascimento"
                        className="col-form-label"
                      >
                        Data de nascimento:
                      </label>
                      <InputMask
                        type="text"
                        className="form-control"
                        value={
                          candidato.datanascimento
                        }
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-habilitacao"
                        className="col-form-label"
                      >
                        Habilitação:
                      </label>

                      <select
                        type="text"
                        className="form-control"
                        value={candidato.habilitacao || ""}
                        readOnly={true}
                        name="habilitacao"
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="N">Não possui</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-cpf"
                        className="col-form-label"
                      >
                        CPF:
                      </label>
                      <InputMask
                        className="form-control"
                        value={candidato.cpf || ""}
                        readOnly={true}
                        name="cpf"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-areainteresse"
                        className="col-form-label"
                      >
                        Área de interesse:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.areainteresse || ""}
                        readOnly={true}
                        name="areainteresse"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Endereço</span>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label htmlFor="recipient-rua" className="col-form-label">
                        Rua:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.rua || ""}
                        readOnly={true}
                        name="rua"
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label htmlFor="recipient-rua" className="col-form-label">
                        Número:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.numero || ""}
                        readOnly={true}
                        name="numero"
                      />
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-complemento"
                        className="col-form-label"
                      >
                        Complemento:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.complemento || ""}
                        readOnly={true}
                        name="complemento"
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="recipient-cep" className="col-form-label">
                        CEP:
                      </label>
                      <InputMask
                        mask="99999-999"
                        className="form-control"
                        value={candidato.cep || ""}
                        readOnly={true}
                        name="cep"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-bairro"
                        className="col-form-label"
                      >
                        Bairro:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.bairro || ""}
                        readOnly={true}
                        name="bairro"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-cidade"
                        className="col-form-label"
                      >
                        Cidade:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={candidato.cidade || ""}
                        readOnly={true}
                        name="cidade"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="recipient-estado"
                        className="col-form-label"
                      >
                        Estado:
                      </label>
                      <select
                        className="form-control"
                        value={candidato.estado || ""}
                        readOnly={true}
                        name="estado"
                      >
                        <option value="">Selecione uma opção</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                        <option value="EX">Estrangeiro</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 divisor">
                    <span className="titulo">Documentos</span>
                    <hr />
                  </div>
                </div>
                {candidato.documentos &&
                  candidato.documentos.split(",").length > 0 && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <table className="table table-sm table-bordered table-striped ">
                            <thead>
                              <tr>
                                <th scope="col">Nome do arquivo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {candidato.documentos.split(",").map((arq, i) => {
                                return (
                                  <tr key={"arq_" + i}>
                                    <th scope="row" width="90%">
                                      <a
                                        href={arq}
                                        download={true}
                                        target="_blank"
                                      >
                                        {arq.split("|")[1]}
                                      </a>
                                    </th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}
      >
        <div style={{ width: "60%" }}>
          <ExcelFile
            element={
              <button
                className="btn btn-primary btn-sm"
                type="button"
                style={{
                  padding: 7,
                  background: "#1f661c",
                }}
                disabled={!dadosExportar.length > 0}
              >
                Exportar{" "}
                <i className="fa fa-file-excel-o " aria-hidden="true"></i>
              </button>
            }
          >
            <ExcelSheet data={dadosExportar} name="Candidatos">
              <ExcelColumn label="Vaga" value="titulo" />
              <ExcelColumn label="Nome" value="nome" />
              <ExcelColumn label="CPF" value="cpf" />
              <ExcelColumn label="Data de Nascimento" value="datanascimento" />
              <ExcelColumn label="Habilitacao" value="habilitacao" />
              <ExcelColumn label="E-mail" value="email" />
              <ExcelColumn label="Telefone" value="telefone" />
              <ExcelColumn label="Area de interesse" value="areainteresse" />
              <ExcelColumn label="Cursos" value="curso" />
              <ExcelColumn label="Formacao" value="formacao" />
              <ExcelColumn label="Autorizacao" value="autorizacao" />
              <ExcelColumn label="Documentos" value="documentos" />
              <ExcelColumn label="Rua" value="rua" />
              <ExcelColumn label="Numero" value="numero" />
              <ExcelColumn label="Complemento" value="complemento" />
              <ExcelColumn label="Bairro" value="bairro" />
              <ExcelColumn label="Cidade" value="cidade" />
              <ExcelColumn label="Estado" value="estado" />
              <ExcelColumn label="CEP" value="cep" />
              <ExcelColumn label="Curriculo" value="documentos" />
            </ExcelSheet>
          </ExcelFile>
        </div>
        <div style={{ width: "40%" }}>
          <div>
            <input
              name="busca"
              onChange={(e) => {
                setBusca(e.target.value);
              }}
              value={busca || ""}
              className="form-control"
              placeholder="Buscar por vaga"
              style={{ width: "89%", float: "left" }}
            />

            <button
              className="btn btn-primary"
              type="button"
              style={{ padding: 9, float: "left", marginLeft: "1%" }}
              onClick={() => {
                buscarVagasCandidato();
              }}
            >
              <i className="fa fa-search fa-lg " aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th scope="col">Vaga</th>
            <th scope="col">Candidatos</th>
          </tr>
        </thead>
        <tbody>
          {candidatos.length ? (
            candidatos.map((t, index) => {
              return (
                <tr key={"_candidatos_" + index}>
                  <td align="middle">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        selectCheck(t.id_vaga, index);
                      }}
                      className="checkbox"
                    />
                  </td>
                  <td>{t.titulo}</td>

                  <td align="left">
                    {t.candidatos.length &&
                      t.candidatos.map((c, index) => {
                        return (
                          <>
                            <span className="candidato" key={"itens_c" + index}>
                              {c.nome}{" "}
                              <span
                                className="icons2"
                                onClick={() => {
                                  setCandidato(c);
                                  setmodal(true);
                                }}
                              >
                                <i
                                  className="fa fa-external-link-square blue"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              {c.documentos && (
                                <a
                                  href={c.documentos}
                                  target="_blank"
                                  rel="opener"
                                >
                                  <i
                                    className="fa fa-download fa-lg green"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              )}
                            </span>
                          </>
                        );
                      })}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>Nunhuma candidato encontrado...</td>
            </tr>
          )}
          {/* <tr>
            <td>
              <nav aria-label="...">
                <ul className="pagination pagination-sm  justify-content-end">
                  {pages.length ? (
                    pages.map((p, i) => {
                      return (
                        <li
                          className={
                            paginaAtual !== i ? "page-item" : "page-item active"
                          }
                          key={"pagination__" + i}
                        >
                          <span
                            className="page-link"
                            href="#"
                            key={"links_" + i}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              setPaginaAtual(i);
                            }}
                          >
                            {i + 1}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <li
                      className={
                        paginaAtual !== 0 ? "page-item" : "page-item active"
                      }
                    >
                      <span
                        className="page-link"
                        href="#"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          setPaginaAtual(0);
                        }}
                      >
                        1
                      </span>
                    </li>
                  )}
                </ul>
              </nav>
            </td>
          </tr> */}
        </tbody>
      </table>
    </>
  );
};

export default CandidatoVaga;
