export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bnavbarNavDropdown"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="img/hebert_home.png" width="100px" alt="logo"></img>
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bnavbarNavDropdown">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <button
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Hebert Engenharia
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="#about">
                  Quem somos
                </a>
                <a className="dropdown-item" href="#features">
                  Nosso negócio
                </a>

                <a className="dropdown-item" href="#portfolio">
                  Obras recem executadas
                </a>

                <a className="dropdown-item" href="#testimonials">
                  Colaboradores
                </a>

                <a className="dropdown-item" href="documentos/politica_hebert.pdf" target="_blank">
                  Política da Hebert
                </a>

              </div>
            </li>

            <li>
              <a href="#services" className="page-scroll">
                Programa de Integridade
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Clientes
              </a>
            </li>
            <li>
              <a href="/vagas" target="_blank" className="page-scroll">
                Trabalhe conosco
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contato
              </a>
            </li>
            {/* <li>
              <a href="/sms" className="page-scroll">
                Campanhas SMS
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
