import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { CookieBanner } from "@palmabit/react-cookie-law";

export const Home = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  const [, setShow] = useState(false);
  const [arquivos, setarquivos] = useState([]);
  const [obras, setobras] = useState([]);

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  const getobras = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getobras", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        var obras = result ? result.filter((obra) => obra.status !== 0) : [];
        setobras(obras);
      });
  };

  const getarquivos = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getarquivos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setarquivos(result);
      });
  };

  useEffect(() => {
    getarquivos();
    getobras();
    setLandingPageData(JsonData);
  }, []);

  const handle = () => {
    setShow(true);
  };

  return (
    <>
      <CookieBanner
        message="Nosso website coleta informações do seu dispositivo e da sua navegação por meio de cookies para permitir funcionalidades como: melhorar o funcionamento técnico das páginas. Para saber mais sobre as informações de cookies que coletamos, acesse a nossa Política de Privacidade."
        wholeDomain={true}
        onAccept={() => {}}
        onAcceptPreferences={() => {}}
        onAcceptStatistics={() => {}}
        onAcceptMarketing={() => {}}
        privacyPolicyLinkText="Política de Privacidade"
        acceptButtonText="ACEITAR TODOS"
        declineButtonText="REJEITAR TODOS"
        managePreferencesButtonText="CONFIGURAÇÕES DE COOKIES"
        savePreferencesButtonText="SALVAR E FECHAR"
        showPreferencesOption={false}
        showStatisticsOption={false}
        showMarketingOption={false}
        styles={{
          button: { border: "none", padding: "8px" },
          dialog: {
            position: "fixed",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: "100000",
            backgroundColor: "rgb(248, 247, 247)",
            padding: "20px 20px 45px 20px",
          },
        }}
      />
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features action={handle} data={landingPageData.Features} />
      <About
        data={landingPageData.About}
        revista={arquivos.filter((ar) => ar.tipo === "6")}
      />
      <Services
        data={landingPageData.Services}
        codigoDeEtica={arquivos.filter((ar) => ar.tipo === "5")}
      />
      <Gallery data={landingPageData.Gallery} obras={obras} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact
        data={landingPageData.Contact}
        data2={landingPageData.Contact1}
      />
    </>
  );
};
