import { useState } from "react";
import "./style.css";
const Usuario = (props) => {
  const [message, setmessage] = useState([]);
  const [alert, setalert] = useState(false);
  const [style, setstyle] = useState("");
  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    salvar();
  };

  const salvar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/salvarusuario", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ usuario: inputs }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (!result.erro) {
          setalert(true);
          setstyle("success");
          setmessage(result.mensagem);
          setInputs({});
        } else {
          setalert(true);
          setstyle("danger");
          setmessage(result.mensagem);
        }
      });
  };

  return (
    <div className="row" style={{ padding: "20px" }}>
      <div className="col-6">
        {alert && (
          <div className={"alert alert-" + style} role="alert">
            {message}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              name="nome"
              className="form-control"
              value={inputs.nome || ""}
              onChange={handleChange}
              placeholder="nome"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo nome é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red ">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Login</label>
            <input
              type="text"
              name="login"
              className="form-control"
              value={inputs.login || ""}
              onChange={handleChange}
              placeholder="Login"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo login é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>

          <div className="form-group">
            <label>Endereço de Email </label>
            <input
              type="email"
              name="email"
              className="form-control"
              value={inputs.email || ""}
              onChange={handleChange}
              aria-describedby="emailHelp"
              placeholder="Enter email"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo email é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              value={inputs.password || ""}
              onChange={handleChange}
              placeholder="Password"
              onInvalid={(e) =>
                e.target.setCustomValidity("O campo senha é obrigatório")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required={!props.editar ? false : true}
              disabled={!props.editar ? false : true}
            />
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>
          <div className="form-group">
            <label>Status do usuário</label>
            <select
              className="form-control"
              name="status"
              value={inputs.status || ""}
              onChange={handleChange}
              aria-label="Default select example"
              required
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Ativo</option>
              <option value="0">Desativar</option>
            </select>
            <small id="emailHelp" className="form-text text-muted red">
              Campo obrigatório
            </small>
          </div>
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Usuario;
