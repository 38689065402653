export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 text-center">
            <img src="img/about.png" className="img-responsive" alt="" />
            <a
              href={
                "http://www.hebertengenharia.com.br/uploads/" +
                props?.revista[0]?.file
              }
              download
            >
              <button type="button" className="btn btn-custom btn-lg">
                Faça Download da revista
              </button>
            </a>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Quem somos</h2>
              <p>
                Especializada em obras industriais e eletromecânicas, a Hebert
                Engenharia é uma empresa que prioriza a segurança, a qualidade,
                a eficiência e a competitividade como alicerces para garantir o
                rigoroso cumprimento de prazos e especificações contratuais,
                gerando os resultados desejados por seus clientes.
                <br />
                <br />
                Há mais de 20 anos atuando no mercado de siderurgia, cimento,
                energia, óleo e gás, a Hebert Engenharia se destaca por ser uma
                empresa moderna e consciente de seus deveres, principalmente em
                relação ao bem-estar de seus colaboradores, fornecedores e
                sociedade, pautando suas atividades de forma responsável e
                sustentável.
                <br />
                <br />
                Além disso, utiliza efetivamente ferramentas de Gerenciamento de
                Projetos baseadas no 'PMBoK' (Project Management Body ok
                Knowledge), fator chave para o sucesso de seus empreendimentos e
                possui tradição de montar times vencedores, capazes de superar
                cenários de crise e manter o foco na busca dos objetivos
                planejados.
              </p>
              <h3>Porque a gente?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>
                      <b>Respeito aos Prazos:</b>
                      <br />
                      <span style={{ paddingLeft: "21px", display: "block" }}>
                        A empresa é conhecida por seus clientes pelo seu
                        controle rigoroso em sua rotina de trabalho respeitando
                        prazos e cronogramas.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>
                      <b>Confiabilidade:</b>
                      <br />
                      <span style={{ paddingLeft: "21px", display: "block" }}>
                        As obras industriais mais elaboradas e complexas são
                        confiadas por nossos clientes e estas são acompanhadas
                        de forma detalhada e com a máxima atenção por nossa
                        equipe técnica.
                      </span>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
