/* import { useState, useEffect } from "react";
import React, { Component } from "react";
import Slider from "react-slick"; */
import Carousel from "./Carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons'
import { faSeedling } from '@fortawesome/free-solid-svg-icons'

export const ListaCampanhas = (props) => {

  return (
  <div id="pag-campanhas">
      <div className="menuVagas" id="menu-campanhas">
        <div className="logoVagas">
          <a href="http://hebertengenharia.com.br/"><img src="img/hebert_home.png" width="100px" alt="logo"></img></a>
        </div>
        <ul className="menuVagasDisponiveis">
          <a href="http://hebertengenharia.com.br/">
            <li>
              <i className="fa fa-arrow-left" aria-hidden="true"/> Retornar
            </li>
          </a>
            <li></li><li></li>
          <a href="#saude" className="page-scroll">
            <li>
              Saúde <FontAwesomeIcon icon={faBookMedical} />
            </li>
          </a>
          <a href="#meio-ambiente" className="page-scroll">
            <li>
              Meio ambiente <FontAwesomeIcon icon={faSeedling} />
            </li>
          </a>
          <a href="#seguranca" className="page-scroll">
            <li>
              Segurança <FontAwesomeIcon icon={faHelmetSafety} />
            </li>
          </a>
        </ul>
      </div>
      <section id="introducao"> 
        <div id="con-titulo">
          <h1 id="titulo-geral-campanhas" className="tx-intro">CAMPANHAS SMS</h1>
        </div>
        <div id="con-slogan">
          <h3 id="slogan-campanhas" className="tx-intro">Cuidar de pessoas é uma responsabilidade intransferível</h3>
        </div>
        <div id="con-intro">
          <p id="intro-geral-campanhas" className="tx-intro ocultar-mob">Bem-vindo ao universo comprometido da Hebert Engenharia, onde a excelência vai além das construções. 
          Estamos orgulhosamente engajados em ações que promovem a segurança, saúde, meio ambiente e o bem-estar de nossos colaboradores. Na vanguarda da construção, nossa empresa também constrói um futuro mais seguro e sustentável para todos.</p>
        </div>
      </section>

      <section id="saude"> 
        <h2 className="titulo-secao">Saúde</h2>
        <p className="intro-campanhas">Reconhecemos que a saúde é fundamental, tanto para nossos colaboradores quanto para a comunidade. Por isso, prezamos rigorosamente em nossos projetos para garantir um ambiente de trabalho seguro. 
        A Hebert Engenharia se orgulha de promover hábitos saudáveis e oferecer suporte médico à toda equipe. Acreditamos que investir na saúde de nossa equipe é essencial para o sucesso a longo prazo de nossa empresa.</p>
        <img className="foto-sms ocultar-dsk" src="https://via.placeholder.com/1200x600/00ff00/ffffff"/>
      </section>

      <hr></hr>

      <section id="meio-ambiente"> 
        <h2 className="titulo-secao">Meio Ambiente</h2>
        <p className="intro-campanhas">Sustentabilidade é o segredo para um futuro estável. Implementamos práticas sustentáveis em nossos projetos, desde a seleção de materiais até a gestão de resíduos. 
        Além disso, estamos envolvidos em iniciativas de reflorestamento e conservação da biodiversidade. Queremos contribuir para um futuro mais verde e sustentável.</p>
        <img className="foto-sms ocultar-dsk" src="https://via.placeholder.com/1200x600/ff0000/ffffff"/>
      </section>
      
      <hr></hr>

      <section id="seguranca"> 
        <h2 className="titulo-secao">Segurança</h2>
        <p className="intro-campanhas">Na Hebert, priorizamos a segurança a cada passo, mantendos padrões e normas minuciosas de segurança em nossos projetos e promovemos uma cultura de segurança proativa. 
        Investimos em treinamento, equipamentos adequados e protocolos de segurança eficazes. A segurança de nossos colaboradores, clientes e comunidades é nossa prioridade número um.</p>
        <img className="foto-sms ocultar-dsk" src="https://via.placeholder.com/1200x600/0000ff/ffffff"/>
        <div id="carouselContainer" className="ocultar-mob ocultar-med">
          <Carousel/>
        </div>  
      </section>

      <hr></hr>

      <section id="conteudos-extra">
        <div id="con-listagemCampanhas">
          <h3 id="tituloListagem-campanhas">Nossas campanhas realizadas:</h3>
          <ul id="lista-campanhas">
            <li><a href="documentos/listagem/prevencao_dengue.pdf" target="_blank" className="link-verde">Prevenção dengue [fev/2024]</a></li>
            <li><a href="documentos/listagem/codigo_etica_hebert.pdf" target="_blank" className="link-verde">Programa integridade & ética [jan/2024]</a></li>
            <li><a href="documentos/listagem/novembro_azul.pdf" target="_blank" className="link-verde">Novembro azul [nov/2023]</a></li>
            <li><a href="documentos/listagem/setembro_amarelo.pdf" target="_blank" className="link-verde">Setembro amarelo [set/2023]</a></li>
            <li><a href="documentos/listagem/parada_conscientizacao.pdf" target="_blank" className="link-verde">Parada de conscientização [ago/2022]</a></li>
            <li><a href="documentos/listagem/organizacao_frente_de_trabalho.pdf" target="_blank" className="link-verde">Frente de trabalho [dez/2018]</a></li>
            <li><a href="documentos/listagem/outubro_rosa.pdf" target="_blank" className="link-verde">Outubro rosa [out/2018]</a></li>
          </ul>
        </div>  
        <div id="con-video-embed" className="ocultar-mob ocultar-med">
          <iframe id="video-embed" src="https://www.youtube.com/embed/VHU811Wr738" title="&#39;O pirata&#39; Campanha de segurança para as mãos - Segurança do Trabalho" 
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </section>
  </div>
  );
};
