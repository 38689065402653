export const ProtecaoDeDados = (props) => {
  return (
    <div>
      <p>
        Considerando que em 2018 foi sancionada a Lei nº 13.709/18, mais
        conhecida como a Lei Geral de Proteção de Dados Pessoais (LGPD), a
        Hebert Engenharia iniciou um projeto que visa a adequação e Compliance
        com essa legislação e com as demais leis setoriais de proteção de dados
        pessoais brasileiras já existentes. Nesse sentido, a Hebert Engenharia
        está comprometida em proteger e resguardar os direitos dos titulares de
        dados, assim como em ser um agente propagador da importância dos
        direitos relativos à privacidade e à proteção de dados pessoais.
      </p>
      <br />
      <p>
        <strong>
          Informações do Encarregado de Proteção de Dados Pessoais (DPO):
        </strong>
        <br />
        <span>Fabiano Hebert Zacaron Gomes</span>
        <br />
        contato para dúvidas, reclamações ou sugestões em relação aos temas de
        privacidade e dados pessoais:
        <a href="mailto:dpo@hebertengenharia.com.br">
          dpo@hebertengenharia.com.br
        </a>
        .
      </p>
      <p>
        <b>
          Como é o Programa de Compliance da Hebert Engenharia para as
          normativas de proteção de dados pessoais?
        </b>
        <br />A Hebert Engenharia tem adotado uma série de medidas para estar em
        Compliance com as leis de proteção de dados pessoais, notadamente à
        LGPD. Neste momento, a Hebert Engenharia está na fase de mapeamento de
        dados, realizando entrevistas em toda a Organização para compreender o
        fluxo de dados pessoais e verificar a adequação com as obrigações legais
        previstas nas leis de proteção de dados pessoais, a fim de realizar o
        processo de Compliance regulatório de proteção de dados pessoais.
        <br /> <br />
        <span>
          <b>Fabiano Hebert Zacaron Gomes</b>
        </span>
      </p>
    </div>
  );
};
