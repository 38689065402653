import { useState, useEffect } from "react";
import Team from "react";

const TeamList = (props) => {
  const [team, setteam] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    var toggler = document.getElementsByClassName("seta");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("seta-down");
      });
    }
  });

  const desativar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/desativarteam", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getteam();
      });
  };

  const getteam = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getteam", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setteam(result);
      });
  };

  useEffect(() => {
    getteam();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desativar </h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body2 text-center">
                Deseja realmente desativar o registro?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    desativar();
                  }}
                >
                  Desativar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">Depoimento</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">
              Editar
            </th>
            <th scope="col" className="text-center">
              Desativar
            </th>
          </tr>
        </thead>
        <tbody>
          {team.length
            ? team.map((t, index) => {
                return (
                  <tr key={index + "_times"}>
                    <th scope="row">{index}</th>
                    <td>{t.nome}</td>
                    <td>{t.depoimento.substring(0, 50) + "..."}</td>
                    <td>{!t.status ? "Desativado" : "Ativo"}</td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          props.setconteudo(<Team inputs={t} editar="true" />);
                          props.setTituloConteudo("Editar time");
                          props.setbread("Time / Listar / Editar");
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o blue"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>

                    {!t.status ? (
                      <td align="middle">
                        <span
                          className="icons green"
                          onClick={() => {
                            setmodal(true);
                            setId(t.id);
                          }}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </span>
                      </td>
                    ) : (
                      <td align="middle">
                        <span
                          className="icons red"
                          onClick={() => {
                            setmodal(true);
                            setId(t.id);
                          }}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </>
  );
};

export default TeamList;
