import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/estilo.css';


const Carousel = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4500
  };

  const images = [
    'https://via.placeholder.com/1200x600/ff0000/ffffff',
    'https://via.placeholder.com/1200x600/00ff00/ffffff',
    'https://via.placeholder.com/1200x600/0000ff/ffffff',
  ];

  return (
    <Slider {...settings} className='carrossel'>
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Slide ${index}`} />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;