import { useState, useEffect } from "react";
import Usuario from ".";

const UsuarioList = (props) => {
  const [usuarios, setusuarios] = useState([]);
  const [modal, setmodal] = useState(false);
  const [id, setId] = useState("");
  const desativar = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/desativarusuario", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((result) => {
        setmodal(false);
        getusuarios();
      });
  };

  const getusuarios = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getusuarios", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setusuarios(result);
      });
  };

  useEffect(() => {
    getusuarios();
  }, []);

  return (
    <>
      {modal && (
        <div className="modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Desativar usuário</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setmodal(false);
                  }}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                Deseja realmente desativar o usuário?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    desativar();
                  }}
                >
                  Desativar
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    setmodal(false);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <table className="table table-hover table-sm  table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">Login</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">
              Editar
            </th>
            <th scope="col" className="text-center">
              Desativar
            </th>
          </tr>
        </thead>
        <tbody>
          {usuarios.length
            ? usuarios.map((usuario, index) => {
                return (
                  <tr key={index + "_usuario"}>
                    <th scope="row">{index}</th>
                    <td>{usuario.nome}</td>
                    <td>{usuario.login}</td>
                    <td>{usuario.email}</td>
                    <td>{!usuario.status ? "Desativado" : "Ativo"}</td>
                    <td align="middle">
                      <span
                        className="icons"
                        onClick={() => {
                          props.setconteudo(
                            <Usuario inputs={usuario} editar="true" />
                          );
                          props.setTituloConteudo("Editar usuário");
                          props.setbread("Usuários  /  Listar / Editar");
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o blue"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>
                    <td align="middle">
                      <span
                        className="icons red"
                        onClick={() => {
                          setmodal(true);
                          setId(usuario.id);
                        }}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </>
  );
};

export default UsuarioList;
