import "./css/sidebar.css";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Usuario from "../usuario";
import Dashboard from "../dashboard";
import UsuarioList from "../usuario/list";
import TeamList from "../team/list";
import Team from "../team/index";
import Contato from "../contato";
import ContatoList from "../contato/list";
import ObraRecente from "../obras/index";
import ObraRecenteList from "../obras/list";
import Cliente from "../cliente";
import ClienteLista from "../cliente/list";
import Arquivo from "../arquivo/index";
import ArquivoListar from "../arquivo/list";
import Vaga from "../vaga";
import VagaList from "../vaga/list";
import CandidatoLista from "../candidato/list";
import CandidatoVaga from "../candidato/candidatoVaga";

export const Admin = (props) => {
  const navigate = useNavigate();
  const [conteudo, setconteudo] = useState(<Dashboard />);
  const [titulo_conteudo, setTituloConteudo] = useState("");
  const [bread, setbread] = useState("Dashboard");

  useEffect(() => {
    function menu() {
      var toggler = document.getElementsByClassName("menuitens");
      var i;
      for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function (el) {
          if (el.srcElement.localName !== "span") {
            this.childNodes[3].classList.toggle("active");
            s(this.childNodes[2]);
          }
        });
      }
    }

    function s(el) {
      el.classList.toggle("seta-down");
    }

    menu();
  }, []);

  const logout = () => {
    localStorage.removeItem("usuario");
    navigate("/");
  };
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 menu">
            <span
              className="titleDash"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setconteudo(
                  <Dashboard
                    setconteudo={setconteudo}
                    setTituloConteudo={setTituloConteudo}
                    setbread={setbread}
                  />
                );

                setTituloConteudo("Principal");
                setbread("Dasboard");
              }}
            >
              <i
                className="fa fa-tachometer"
                aria-hidden="true"
                style={{ marginRight: "8px" }}
              ></i>
              Dashboard
            </span>

            <ul>
              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Usuários</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li className="nestedLi">
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Usuario inputs={""} />);
                        setTituloConteudo("Adicionar usuários");
                        setbread("Usuários  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setconteudo(
                          <UsuarioList
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar usuários");
                        setbread("Usuários  /  Listar");
                      }}
                    >
                      Listar usuários
                    </span>
                  </li>
                </ul>
              </li>
              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Time</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Team inputs={""} />);
                        setTituloConteudo("Adicionar time");
                        setbread("Time  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <TeamList
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar time");
                        setbread("Time  /  Listar");
                      }}
                    >
                      Listar time
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Contatos</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Contato inputs={""} />);
                        setTituloConteudo("Adicionar contato");
                        setbread("Contato  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <ContatoList
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar contato");
                        setbread("Contato  /  Listar");
                      }}
                    >
                      Listar time
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Obras recentes</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<ObraRecente inputs={""} />);
                        setTituloConteudo("Adicionar obra");
                        setbread("Obras  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <ObraRecenteList
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar obras");
                        setbread("Obras  /  Listar");
                      }}
                    >
                      Listar obras
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Clientes</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Cliente inputs={""} />);
                        setTituloConteudo("Adicionar cliente");
                        setbread("Cliente  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <ClienteLista
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar clientes");
                        setbread("Cliente  /  Listar");
                      }}
                    >
                      Listar clientes
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Processo seletivo</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    {" "}
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <CandidatoLista
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar candidatos");
                        setbread("Processo seletivo  /  Listar candidatos");
                      }}
                    >
                      Candidatos
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <CandidatoVaga
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar Processo seletivo");
                        setbread("Processo seletivo  /  Candidato por Vaga");
                      }}
                    >
                      Candidato vaga
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Vagas</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Vaga inputs={""} />);
                        setTituloConteudo("Adicionar vaga");
                        setbread("Vagas  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <VagaList
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar vagas");
                        setbread("Vagas  /  Listar");
                      }}
                    >
                      Listar vagas
                    </span>
                  </li>
                </ul>
              </li>

              <li className="menuitens">
                <i
                  className="fa fa-dot-circle-o"
                  aria-hidden="true"
                  style={{ marginRight: "8px" }}
                ></i>
                <span className="itemMenu">Arquivos</span>
                <i className="fa fa-chevron-right seta"></i>
                <ul className="nested">
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(<Arquivo inputs={""} />);
                        setTituloConteudo("Adicionar arquivo");
                        setbread("Arquivo  /  Adicionar");
                      }}
                    >
                      Adicionar
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer", display: "block" }}
                      onClick={() => {
                        setconteudo(
                          <ArquivoListar
                            setconteudo={setconteudo}
                            setTituloConteudo={setTituloConteudo}
                            setbread={setbread}
                          />
                        );
                        setTituloConteudo("Listar arquivos");
                        setbread("Arquivo  /  Listar");
                      }}
                    >
                      Listar arquivos
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-12">
                <button
                  className="btsair"
                  id="sair"
                  onClick={() => {
                    logout();
                  }}
                  style={{ border: "none", background: "none" }}
                >
                  Sair <i className="fa fa-sign-out" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div className="row">
              <div className="cards">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span
                        style={{ cursor: "pointer", display: "block" }}
                        onClick={() => {
                          setconteudo(<Dashboard />);
                          setTituloConteudo("");
                          setbread("Dashboard");
                        }}
                      >
                        Principal
                      </span>
                    </li>
                    <li className="breadcrumb-item">{bread}</li>
                  </ol>
                </nav>

                <div className="card-header"></div>
                <div className="card-body">
                  <h5 className="card-title">{titulo_conteudo}</h5>
                  {conteudo}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};
