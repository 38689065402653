export const Politica = (props) => {
  return (
    <div className="pdd">
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Introdução:</strong>
        </span>
        <br />A Hebert Engenharia possui portal eletrônico que têm como objetivo
        a divulgação de seus produtos e serviços. Nesse sentido, a presente
        Política de Privacidade tem como objetivo fornecer orientações sobre
        como ocorre a coleta, uso, dentre outros tratamentos, das informações e
        dados pessoais daqueles que visitam e utilizam as funcionalidades
        disponíveis no site.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Definições:</strong>
        </span>
        <br />
        DADOS PESSOAIS: Qualquer informação que identifica, ou possa identificar
        uma pessoa natural. Também são considerados dados pessoais aqueles
        utilizados para formação do perfil comportamental de determinada pessoa
        natural.
      </p>
      <p>
        LEI GERAL DE PROTEÇÃO DE DADOS (“LGPD”): Diploma normativo (Lei nº
        13.709, de 14 de agosto de 2018) que dispõe sobre o tratamento de dados
        pessoais em meios digitais ou físicos realizados por pessoa natural ou
        por pessoa jurídica, de direito público ou privado, tendo como objetivo
        defender os titulares de dados pessoais e ao mesmo tempo permitir o uso
        dos dados para finalidades diversas, equilibrando interesses e
        harmonizando a proteção da pessoa humana com o desenvolvimento
        tecnológico e econômico.
      </p>
      <p>
        TITULAR DE DADOS PESSOAIS (“TITULAR”): Pessoa natural a quem se referem
        os dados pessoais que são objeto de tratamento.
      </p>
      <p>
        TRATAMENTO DE DADOS PESSOAIS (“TRATAMENTO”): Toda operação realizada com
        dados pessoais, como as que se referem a coleta, produção, recepção,
        classificação, utilização, acesso, reprodução, transmissão,
        distribuição, processamento, arquivamento, armazenamento, eliminação,
        avaliação, controle da informação, modificação, comunicação,
        transferência, difusão ou extração.
      </p>
      <p>
        USUÁRIOS: Para fins da presente Política, os usuários do portal
        eletrônico da Hebert Engenharia serão considerados como os indivíduos
        que realizam visitas ao site.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Princípios Gerais:</strong>
        </span>
        <br />A Hebert Engenharia tem norteado seu Programa de Compliance às
        leis de proteção de dados pessoais nos princípios trazidos pela LGPD, os
        quais estão indicados da seguinte forma:
      </p>
      <ul>
        <li>
          FINALIDADE: Realização do tratamento para propósitos legítimos,
          específicos, explícitos e informados ao titular, sem possibilidade de
          tratamento posterior de forma incompatível com essas finalidades.
        </li>
        <li>
          ADEQUAÇÃO: Compatibilidade do tratamento com as finalidades informadas
          ao titular, de acordo com o contexto do tratamento.
        </li>
        <li>
          NECESSIDADE: Limitação do tratamento ao mínimo necessário para a
          realização de suas finalidades, com abrangência dos dados pessoais
          pertinentes, proporcionais e não excessivos em relação às finalidades
          do tratamento de dados.
        </li>
        <li>
          LIVRE ACESSO: Garantia, aos titulares, de consulta facilitada e
          gratuita sobre a forma e a duração do tratamento, bem como sobre a
          integralidade de seus dados pessoais.
        </li>
        <li>
          QUALIDADE DE DADOS: Garantia, aos titulares, de exatidão, clareza,
          relevância e atualização dos dados, de acordo com a necessidade e para
          o cumprimento da finalidade de seu tratamento.
        </li>
        <li>
          TRANSPARÊNCIA: Garantia, aos titulares, de informações claras,
          precisas e facilmente acessíveis sobre a realização do tratamento e os
          respectivos agentes de tratamento, observados os segredos Comercial e
          industrial.
        </li>
        <li>
          SEGURANÇA: Utilização de medidas técnicas e administrativas aptas a
          proteger os dados pessoais de acessos não autorizados e de situações
          acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
          difusão.
        </li>
        <li>
          PREVENÇÃO: Adoção de medidas para prevenir a ocorrência de danos em
          virtude do tratamento de dados pessoais.
        </li>
        <li>
          RESPONSABILIZAÇÃO E PRESTAÇÃO DE CONTAS: Demonstração, pelo agente, da
          adoção de medidas eficazes e capazes de comprovar a observância e o
          cumprimento das normas de proteção de dados pessoais e, inclusive, da
          eficácia dessas medidas.
        </li>
        <li>
          NÃO DISCRIMINAÇÃO: Impossibilidade de realização do tratamento para
          fins discriminatórios ilícitos ou abusivos.
        </li>
      </ul>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Dados pessoais tratados pela Hebert Engenharia:</strong>
        </span>
        <br />A Hebert Engenharia poderá coletar as informações inseridas
        ativamente por você, “Usuário”, no momento do contato ou cadastro,
        através do preenchimento do formulário de contato e, ainda, informações
        coletadas automaticamente ao utilizar os nossos serviços. Há, assim, o
        tratamento de dois tipos de dados pessoais: (i) os fornecidos pelo
        próprio Usuário e (ii) aqueles coletados automaticamente pela Hebert
        Engenharia.
      </p>
      <p style={{ paddingLeft: "40px" }}>
        I. Dados Pessoais Fornecidos pelo Usuário
        <br />A Hebert Engenharia coleta todos os dados pessoais inseridos ou
        encaminhados ativamente pelo Usuário ao contatar ou se cadastrar em
        nosso portal. São eles: Nome completo, e-mail, telefone.
        Independentemente de quais dados pessoais o Usuário fornece ativamente à
        Hebert Engenharia, nós apenas faremos uso daqueles efetivamente
        relevantes e necessários para o alcance das finalidades a ele declaradas
        na coleta dos dados.
      </p>
      <p style={{ paddingLeft: "40px" }}>
        II. Dados coletados automaticamente pela Hebert Engenharia
        <br />A Hebert Engenharia coleta uma série de informações de modo
        automático nos portais de interação com o Usuário, tais como:
        características do dispositivo de acesso, do navegador, IP (com data e
        hora), origem do IP, informações sobre cliques, páginas acessadas,
        dentre outros. Para tal coleta, a Hebert Engenharia fará uso de algumas
        tecnologias padrões, como cookies, pixel tags, beacons e local shared
        objects, que são utilizadas com o propósito de melhorar a sua
        experiência de navegação em nossos serviços, de acordo com os seus
        hábitos e preferências.
      </p>
      <p>
        A Hebert Engenharia não trata dados definidos como sensíveis, entendido
        como aqueles relacionados a origem racial ou étnica, convicção
        religiosa, opinião política, filiação a sindicato ou a organização de
        caráter religioso, filosófico ou político, dado referente à saúde ou à
        vida sexual, dado genético ou biométrico, tampouco dados de crianças,
        assim considerados os indivíduos menores de 12 anos, para as finalidades
        gerais aqui dispostas. Na remota hipótese em que for necessário o
        tratamento desses dados, solicitaremos o consentimento específico e
        destacado do Usuário ou dos responsáveis deste, com base nas hipóteses
        autorizadoras pela Lei Geral de Proteção de Dados.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Finalidades de tratamento de dados:</strong>
        </span>
        <br />
        Os dados pessoais tratados pela Hebert Engenharia têm como principais
        finalidades a possibilidade do Usuário conhecer a oferta de produtos e
        serviços pela plataforma, o fornecimento de informações via formulário
        de contato, identificar os Usuários que navegam no site e manter os
        dados de Usuários atualizados.
      </p>
      <p>
        A Hebert Engenharia poderá utilizar as suas informações em demais
        serviços prestados diretamente pela Hebert Engenharia, respeitadas as
        finalidades expostas e o consentimento que você previamente nos
        concedeu, quando houver a exigência legal dessa coleta.
      </p>
      <p>
        Em alguns casos, poderá tratar seus dados pessoais quando necessários
        para o cumprimento de obrigação legal ou exercício regular de direitos
        em processo judicial, administrativo ou arbitral.
      </p>
      <p>
        Além disso, também poderá tratar dados pessoais com base em seu
        interesse legítimo, sempre no limite de sua expectativa, e nunca em
        prejuízo de seus interesses, direitos e liberdades fundamentais.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Compartilhamento de dados pessoais:</strong>
        </span>
      </p>
      <p>
        A Hebert Engenharia poderá compartilhar as suas informações pessoais,
        nas hipóteses abaixo expostas:
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <strong>A.</strong> Com empresas parceiras e fornecedores no
        desenvolvimento e prestação de serviços voltados aos clientes;
        <br />
        <strong>B.</strong> Com autoridades, entidades governamentais ou outros
        terceiros institucionais, para a proteção dos interesses da Hebert
        Engenharia em qualquer tipo de conflito, incluindo ações judiciais e
        processos administrativos;
        <br />
        <strong>C.</strong> No caso de transações e alterações societárias
        envolvendo a Hebert Engenharia, em que a transferência das informações
        será necessária para a continuidade dos serviços;
        <br />
        <strong>D.</strong> Mediante ordem judicial ou pelo requerimento de
        autoridades administrativas que detenham competência legal para a sua
        requisição.
        <br />
        <strong>E.</strong> Com instituições financeiras, para análise e
        proteção de crédito.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>
            Medidas de Segurança para a proteção dos dados pessoais:
          </strong>
        </span>
      </p>
      <p>
        Qualquer informação fornecida pelo Usuário será coletada e guardada de
        acordo com os mais rígidos padrões de segurança. Para tanto, a Hebert
        Engenharia adota diversas precauções, em observância às diretrizes sobre
        padrões de segurança estabelecidas nas legislações e sua Política de
        Segurança da Informação, tais como:
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <strong>A.</strong> Utiliza os mais recentes métodos e equipamentos
        disponíveis no mercado para criptografar e anonimizar os seus dados
        pessoais, quando necessário;
        <br />
        <strong>B.</strong> Possui proteção contra acesso não autorizado a seus
        sistemas;
        <br />
        <strong>C.</strong> Somente autoriza o acesso de pessoas específicas ao
        local onde são armazenadas as suas informações pessoais, desde que este
        acesso seja essencial ao desenvolvimento da atividade pretendida;
        <br />
        <strong>D.</strong> Garante que aqueles agentes, funcionários internos
        ou parceiros externos que realizarem o tratamento de dados pessoais
        deverão se comprometer a manter o sigilo absoluto das informações
        acessadas, bem como de adotar as melhores práticas para manuseio destas
        informações, conforme determinado nas políticas e procedimentos.
      </p>
      <p>
        O acesso às informações coletadas é restrito aos colaboradores e às
        pessoas autorizadas. Aqueles que se utilizarem indevidamente dessas
        informações, em violação desta Política de Privacidade e a de Segurança
        da Informação, estarão sujeitos a sanções disciplinares e legais
        cabíveis.
      </p>
      <p>
        Além dos esforços técnicos, a Hebert Engenharia também adota medidas
        institucionais visando a proteção de dados pessoais, de modo que mantém
        programa de privacidade aplicado às suas atividades, constantemente
        atualizado.
      </p>
      <p>
        Embora a Hebert Engenharia adote os melhores esforços no sentido de
        preservar a sua privacidade e proteger os seus dados pessoais, nenhuma
        transmissão de informações é totalmente segura, de modo que a Hebert
        Engenharia não pode garantir integralmente que todas as informações que
        recebe e/ou envia não sejam alvo de acessos não autorizados perpetrados
        por meio de métodos desenvolvidos para obter informações de forma
        indevida, como falhas técnicas, vírus ou invasões do banco de dados do
        site.
      </p>
      <p>
        De qualquer forma, na remota hipótese de incidência de episódios desta
        natureza, a Hebert Engenharia garante o pleno esforço para remediar as
        consequências do evento.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Armazenamento de dados:</strong>
        </span>
      </p>
      <p>
        Os seus dados pessoais serão automaticamente eliminados pela Hebert
        Engenharia, quando deixarem de ser úteis para os fins que foram
        coletados e não forem mais necessários para cumprir qualquer obrigação
        legal direcionada à Hebert Engenharia, ou quando o Usuário solicitar a
        sua eliminação, exceto se a manutenção do dado for expressamente
        autorizada por lei.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Direitos dos titulares de dados pessoais:</strong>
        </span>
      </p>
      <p>
        A Hebert Engenharia respeita e garante ao Usuário, a possibilidade de
        apresentar solicitações de tratamento dos seus dados pessoais, baseadas
        nos seguintes direitos, previstos no art. 18 da LGPD:
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <strong>A.</strong> Confirmação da existência de tratamento;
        <br />
        <strong>B.</strong> Acesso aos dados pessoais;
        <br />
        <strong>C.</strong> Correção de dados incompletos, inexatos ou
        desatualizados;
        <br />
        <strong>D.</strong> Anonimização, bloqueio ou eliminação de dados
        desnecessários, excessivos ou tratados em desconformidade com a lei;
        <br />
        <strong>E.</strong> Portabilidade dos dados a outro fornecedor de
        serviço ou produto, mediante requisição expressa pelo Usuário;
        <br />
        <strong>F.</strong> Eliminação, sempre que possível, dos dados tratados
        do Usuário;
        <br />
        <strong>G.</strong> Obtenção de informações sobre as entidades com as
        quais compartilhou os seus dados;
        <br />
        <strong>H.</strong> Informação sobre a possibilidade de o Usuário não
        fornecer o consentimento, bem como de ser informado sobre as
        consequências em caso de negativa;
        <br />
        <strong>I.</strong> Revogação do consentimento.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Exercício dos direitos pelo titular dos dados:</strong>
        </span>
      </p>
      <p>
        Os seus direitos como titular de dados pessoais poderão ser exercidos
        diretamente por você, a partir do contato direto com o DPO.
      </p>
      <p>
        O Usuário fica ciente que a eventual solicitação de exclusão de
        informações essenciais para a prestação do serviço implicará no término
        de sua relação contratual, com o consequente cancelamento dos serviços
        então prestados.
      </p>
      <p>
        A Hebert Engenharia empreenderá todos os esforços razoáveis para atender
        as requisições feitas por Usuários no menor espaço de tempo possível. No
        entanto, fatores justificáveis, tais como a complexidade da ação
        requisitada, poderão atrasar ou impedir o seu rápido atendimento, sendo
        certo que, em caso de demora, apresentará os devidos motivos.
      </p>
      <p>
        Por fim, o Usuário deve estar ciente que a sua requisição poderá ser
        legalmente rejeitada, seja por motivos formais (a exemplo de sua
        incapacidade de comprovar sua identidade) ou legais (a exemplo do pedido
        de exclusão de dados cuja manutenção é livre exercício de direito pela
        Hebert Engenharia), sendo certo que, na hipótese de impossibilidade de
        atendimento destas requisições, a Hebert Engenharia apresentará as
        justificativas razoáveis.
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>
            Definição de cookies e sua utilização pela Hebert Engenharia:
          </strong>
        </span>
      </p>
      <p>
        Cookies são arquivos ou informações que podem ser armazenadas em seus
        dispositivos quando você visita os websites ou utiliza os serviços
        on-line da Hebert Engenharia. Geralmente, um cookie contém o nome do
        site que o originou, seu tempo de vida e um valor, que é gerado
        aleatoriamente.
      </p>
      <p>
        A Hebert Engenharia utiliza cookies para facilitar o uso e melhor
        adaptar seu website e aplicações aos interesses e necessidades de seus
        Usuários, bem como para auxiliar e melhorar suas estruturas e seus
        conteúdos. Os cookies também podem ser utilizados para acelerar suas
        atividades e experiências futuras em nossos serviços.
      </p>
      <p>
        Por meio de cookies, o site armazena informações sobre as atividades do
        navegador, incluindo endereço IP e a página acessada. Esses registros de
        atividades (logs) serão utilizados apenas para fins estatísticos e de
        métricas dos serviços disponibilizados ou para a investigação de fraudes
        ou de alterações indevidas em seus sistemas e cadastros, não tendo como
        finalidade o fornecimento dos dados a terceiros sem autorização expressa
        do Usuário.
      </p>
      <p>
        Esses registros poderão compreender dados como o endereço IP do Usuário,
        as ações efetuadas no site, as páginas acessadas, as datas e horários de
        cada ação e de acesso a cada página do site, as informações sobre o
        dispositivo utilizado, versão de sistema operacional, navegador, dentre
        outros aplicativos instalados.
      </p>
      <p>
        Os cookies podem ser divididos de acordo com a informação que armazenam
        e sua finalidade, a saber:
      </p>
      <p style={{ paddingLeft: "40px" }}>
        <strong>A.</strong> Cookies necessários: São essenciais para que o
        website da Hebert Engenharia carregue corretamente e permita que você
        navegue corretamente, bem como faça o uso de todas as funcionalidades
        disponíveis.
        <br />
        <strong>B.</strong> Cookies de Desempenho: Ajudam a entender como os
        visitantes interagem com a página da Hebert Engenharia, fornecendo
        informações sobre as áreas visitadas, o tempo de visita ao site e
        quaisquer problemas encontrados, como mensagens de erro.
        <br />
        <strong>C.</strong> Cookies Funcionais: Permitem que a página se lembre
        de suas escolhas, para proporcionar uma experiência personalizada.
        Possibilitam que os Usuários assistam a vídeos, utilizem ferramentas
        sociais, campos para comentários, fóruns e dentre outros.
        <br />
        <strong>D.</strong> Cookies de Marketing: São utilizados para fornecer
        mais conteúdo relevante e do interesse dos Usuários. Podem ser
        utilizados para apresentar publicidade com um maior direcionamento ou
        limitar o número que esta é veiculada, nas páginas da Hebert Engenharia.
      </p>
      <p>
        A qualquer momento você poderá revogar a sua autorização quanto à
        utilização dos cookies, utilizando, as configurações de seu navegador de
        preferência.
      </p>
      <p>
        Contudo, alertamos que, de acordo com a escolha realizada, certas
        funcionalidades poderão não funcionar da maneira idealmente esperada.
      </p>
      <p>
        <strong>Encarregado de Dados (DPO):</strong>
      </p>
      <p>
        O encarregado de dados – também chamado de DPO (Data Protection Officer)
        – é o responsável indicado para atuar como canal de comunicação entre a
        Hebert Engenharia e os titulares dos dados. A DPO da Hebert Engenharia é
        o Fabiano Hebert Zacaron Gomes
        <span>e seu contato é dpo@hebertengenharia.com.br.</span>
      </p>
      <p>
        <span style={{ textDecoration: "underline" }}>
          <strong>Legislação aplicável:</strong>
        </span>
      </p>
      <p>
        Esta Política foi elaborada para atendimento e em conformidade com a Lei
        Federal nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais), Lei
        Federal nº 12.965/2014 (Marco Civil da Internet) e Lei Federal nº
        8.078/1990 (Código de Defesa do Consumidor), sem prejuízo de observância
        das demais legislações aplicáveis para a Hebert Engenharia.
      </p>
    </div>
  );
};
