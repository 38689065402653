import { useState, useEffect } from "react";
import { Politica } from "./politica";
import { ProtecaoDeDados } from "./protecaoDeDados";
import InputMask from "react-input-mask";

const initialState = {
  name: "",
  email: "",
  message: "",
  telefone: "",
  departamento: "",
};
export const Contact = (props) => {
  const [{ name, email, message, telefone, departamento }, setState] =
    useState(initialState);
  const [open, setOpen] = useState(false);
  const [conteudo, setConteudo] = useState("");
  const [, setCheck] = useState("");
  const [alert, setAlert] = useState(false);
  const [contatos, setcontatos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setalertSuccess] = useState(false);
  const [alertError, setalertError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const openModal = (conteudo) => {
    setConteudo(conteudo);
    setOpen(true);
  };

  const getcontatos = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getcontatoativos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setcontatos(result);
      });
  };

  useEffect(() => {
    getcontatos();
  }, []);

  const handleSubmit = async (e) => {
    setAlert(false);
    setLoading(true);
    e.preventDefault();

    await fetch("https://www.hebertengenharia.com.br/api/enviaemail", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        mail: email,
        nome: name,
        mensagem: message,
        departamento: departamento,
        telefone: telefone,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.retorno) {
          document.getElementById("formulario").reset();
          document.getElementById("telefone").value = "";
          setalertSuccess(true);
          setLoading(false);
        } else {
          setalertError(true);
        }
      });
  };

  return (
    <div>
      {open && (
        <div className="pop2">
          <div
            className="close"
            onClick={() => {
              setOpen(false);
            }}
          >
            <i className="fa fa-times"></i>
          </div>
          {conteudo}
        </div>
      )}
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Fale Conosco</h2>
                <p>
                  Estamos sempre prontos para atendê-lo. Envie um email
                  preenchendo o formulário abaixo.
                </p>
              </div>
              {alertSuccess && (
                <>
                  <div className="alert alert-success" role="alert">
                    Email enviado com sucesso! Aguarde o retorno da nossa
                    equipe.
                  </div>
                </>
              )}

              {alertError && (
                <>
                  <div className="alert alert-danger" role="alert">
                    Ocorreu um erro ao enviar a mensagem. Por favor, tente novamente mais tarde.
                  </div>
                </>
              )}

              {alert === true && (
                <div className="row">
                  <i
                    className="fa fa-exclamation-triangle"
                    aria-hidden="true"
                  ></i>
                  Atenção o checkbox ciente deve ser marcado.
                </div>
              )}
              <form name="sentMessage" id="formulario" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome"
                        required={
                          departamento !== "r.matos.qsms@gmail.com" &&
                          departamento !==
                            "denunciasegura@hebertengenharia.com.br"
                        }
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <InputMask
                        mask="(99)99999-9999"
                        className="form-control"
                        id="telefone"
                        name="telefone"
                        placeholder="Telefone"
                        required={
                          departamento !== "r.matos.qsms@gmail.com" &&
                          departamento !==
                            "denunciasegura@hebertengenharia.com.br"
                        }
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required={
                          departamento !== "r.matos.qsms@gmail.com" &&
                          departamento !==
                            "denunciasegura@hebertengenharia.com.br"
                        }
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        id="departamento"
                        name="departamento"
                        className="select"
                        required
                        onChange={handleChange}
                      >
                        <option value="">Selecione um setor de contato </option>
                        <option value="diretoria@hebertengenharia.com.br">
                          DIRETORIA - INTEGRAÇÃO - ENG. LESSANDRO HEBERT
                        </option>
                        <option value="custos@hebertengenharia.com.br">
                          CUSTOS - ENG. FABIANO HEBERT
                        </option>
                        <option value="compras2@hebertengenharia.com.br">
                          AQUISIÇÕES - MARCUS SANTOS
                        </option>
                        <option value="planejamento@hebertengenharia.com.br">
                          ESCOPO - ENG. GABRIEL LANA
                        </option>
                        <option value="engenharia@hebertengenharia.com.br">
                          TEMPO - ENG. EDISON FONSECA
                        </option>
                        <option value="dp@hebertengenharia.com.br">
                          DEPARTAMENTO DE PESSOAS - ALINE NASCIMENTO
                        </option>
                        <option value="pagamentos@hebertengenharia.com.br">
                          PAGAMENTOS - FÁTIMA REIS
                        </option>
                        <option value="secretaria@hebertengenharia.com.br">
                          SECRETARIA - CRISTIANE RODRIGUES{" "}
                        </option>
                        <option value="comunicacoes@hebertengenharia.com.br">
                          FALE CONOSCO - ENG. FABIANO HEBERT
                        </option>
                        <option value="r.matos.qsms@gmail.com">
                          CONSULTA E PARTICIPAÇÃO DE TRABALHADORES - RODRIGO MATOS{" "}
                        </option>
                        <option value="denunciasegura@hebertengenharia.com.br">
                          DENÚNCIA SEGURA
                        </option>
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensagem"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkbox"
                    name="checkbox"
                    onChange={(el) => {
                      setCheck(el.target.value);
                    }}
                    required
                  />
                  <label
                    className="form-check-label checkbox"
                    htmlFor="checkbox"
                  >
                    Declaro estar ciente e de acordo que meus dados pessoais são
                    coletados com a específica finalidade de permitir o contato
                    pela Hebert Engenharia para informações sobre os
                    empreendimentos por ela incorporados e construídos
                    utilizados e descartados de acordo com as normas de
                    segurança da informação e disposições da Lei Geral de
                    Proteção de Dados Pessoais - LGPD (Lei nº 13.709/18).
                  </label>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensagem
                  {loading && <span className="fa fa-cog fa-spin"></span>}
                </button>
              </form>
            </div>

            <div className="row">
              <div className="section-title">
                <h2>Contatos por departamento</h2>
              </div>

              <table className="table table-responsive table-contato">
                <thead className="thead">
                  <tr>
                    <th>SETOR</th>
                    <th>NOME/EMAIL</th>
                  </tr>
                </thead>
                <tbody>
                  {contatos.map((c, i) => {
                    return (
                      <tr key={"contatos_" + i}>
                        <td>{c.setor}</td>
                        <td>
                          {c.nome !== " " && (
                            <>
                              <i className="fa fa-user" aria-hidden="true"></i>{" "}
                              {c.nome}
                              <br />
                            </>
                          )}
                          <i
                            className="fa fa-envelope"
                            aria-hidden="true"
                          ></i>{" "}
                          {c.email}{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <h3>Nossas unidades</h3>
            <div>
              <div className="contact-item">
                <p>
                  <span>
                    {props.data ? props.data.filial : "loading"}
                  </span>
                  <i className="fa fa-map-marker"/>{props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefone
                  </span>{" "}
                  {props.data ? props.data.phone1 : "loading"}
                  {" - "}
                  {props.data ? props.data.phone2 : "loading"}
                </p>
              </div>
            </div>

            <div className="contact-item">
              <hr className="hr" />
            </div>

            <div>
              <div className="contact-item">
                <p>
                  <span>
                    {props.data2 ? props.data2.filial : "loading"}
                  </span>
                  <i className="fa fa-map-marker"/>{props.data2 ? props.data2.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Telefone
                  </span>{" "}
                  {props.data2 ? props.data2.phone1 : "loading"}
                  {" - "}
                  {props.data2 ? props.data2.phone2 : "loading"}
                </p>
              </div>
            </div>

            <div className="contact-item">
              <hr className="hr" />
            </div>

            <div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-clock-o"></i> Horário de Funcionamento
                  </span>{" "}
                  07:30 - 17:30 | Segunda a Sexta-Feira
                </p>
              </div>
              <div className="contact-item">
                <hr className="hr" />
              </div>
              <div className="contact-item">
                <div id="3" className="denuncia">
                  Denúncia Segura
                </div>
                <span className="fontes">
                  <i className="fa fa-phone"></i>
                  0800 800 4500
                </span>
              </div>
            </div>
          </div>
          {/*<div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/hebertengenharia">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/hebertengenharia">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/hebertengenharia">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            <span className="texto-footer">
              &copy; 2022 Hebert Engenharia. All Rights Reserved.
            </span>
            <span
              style={{
                color: "#427bb7",
                marginLeft: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal(<Politica />);
              }}
            >
              Política de Privacidade
            </span>
            <span
              style={{
                color: "#427bb7",
                marginLeft: "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                openModal(<ProtecaoDeDados />);
              }}
            >
              Proteção de dados
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
