import { useState, useEffect } from "react";

export const Testimonials = (props) => {
  const [team, setteam] = useState([]);

  const getteam = async () => {
    await fetch("https://www.hebertengenharia.com.br/api/getteamativos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => {
        setteam(result);
      });
  };

  useEffect(() => {
    getteam();
  }, []);

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Venha para o Time</h2>
          <p>
            Mais de 20 anos promovendo felicidade e prosperidade para seu time e clientes!
          </p>
        </div>
        <div className="row">
          {team.length > 0
            ? team.map((d, i) => (
                <div key={`team-${i}`} className="col-md-12">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      <img
                        src={`http://www.hebertengenharia.com.br/uploads/${d.file}`}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.depoimento}"</p>
                      <div className="testimonial-meta">
                        {d.nome} - {d.cargo}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "carregando..."}
        </div>
      </div>
    </div>
  );
};
